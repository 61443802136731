import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import styles from './Menu.module.scss';

export const PureMenu = ({ data, horizontal, bold }) => {
  const { menu } = data.site.siteMetadata;
  return (
    <nav className={horizontal ? `${styles['menu']} ${styles['horizontal']}` : styles['menu']}>
      <ul className={styles['menu__list']}>
        {
          <li className={styles['menu__list-item']} key={menu[0].path}>
            <Link
              to={menu[0].path}
              className={styles['menu__list-item-link'] + (bold ? ` ${styles['bold']}` : '')}
              activeClassName={styles['menu__list-item-link--active']}
            >
              {menu[0].label}
            </Link>
          </li>
        }
        {
          <li className={styles['menu__list-item']} key={menu[1].path}>
            <Link
              to={menu[1].path}
              className={styles['menu__list-item-link'] + (bold ? ` ${styles['bold']}` : '')}
              activeClassName={styles['menu__list-item-link--active']}
            >
              {menu[1].label}
            </Link>
          </li>
        }
        {
          <li className={styles['menu__list-item']} key={menu[5].path}>
            <Link
              to={menu[5].path}
              className={styles['menu__list-item-link'] + (bold ? ` ${styles['bold']}` : '')}
              activeClassName={styles['menu__list-item-link--active']}
            >
              {menu[5].label}
            </Link>
          </li>
        }
      </ul>
    </nav>
  );
};

export const MenuPost = props => (
  <StaticQuery
    query={graphql`
      query MenuPostQuery {
        site {
          siteMetadata {
            menu {
              label
              path
            }
          }
        }
      }
    `}
    render={data => <PureMenu {...props} data={data} />}
  />
);

export default MenuPost;
